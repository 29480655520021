<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-11 14:46:45
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-13 14:06:22
-->
<template>
    <div class="styleType">
        <div class="add-wrap">
            <el-button @click="isShowScene = true" class="btns" type="primary">添加风格类型</el-button>
        </div>
        <div class="table-wrap" v-loading="loading">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column align="center" prop="styleTypeId" label="ID"></el-table-column>
                <el-table-column align="center" prop="styleTypeName" label="功能风格名称" ></el-table-column>
                <el-table-column align="center" prop="scene_count" label="全景使用数" ></el-table-column>
                <el-table-column align="center" prop="sort" label="排序" ></el-table-column>
                <el-table-column align="center" prop="create_timex" label="创建时间" ></el-table-column>
                <el-table-column align="center" label="操作" width="90">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog
            :visible.sync="isShowScene" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title">创建全景风格类型</div>
            <div class="content">
                <el-form :model="styleForm" :rules="styleRules" ref="styleForm" label-width="110px" class="demo-ruleForm">
                    <el-form-item label="风格类型名称" prop="functionTypeName">
                        <el-input style="width: 390px" v-model="styleForm.styleTypeName" placeholder="请输入风格类型名称"></el-input>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input-number v-model="styleForm.sort" :min="1" ></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="creationStyle" type="primary">立刻创建</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
    data () {
        return {
            loading: false,
            tableData: [],
            isShowScene: false,
            styleForm: {
                styleTypeName: '',
                sort: ''
            },
            styleRules: {
                styleTypeName: [
                    { required: true, message: '请输入风格类型名称', trigger: 'blur' },
                ],
            },
            btnLoading: false
        }
    },
    mounted () {
        this.getStyleIndex();
    },
    methods: {
        // 创建
        creationStyle(){
            this.$refs.styleForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    common.connect('/customerservicev1/scene/storeStyle',this.styleForm,(res)=>{
                        this.getStyleIndex();
                        this.isShowScene = false;
                        this.btnLoading = false;
                    })
                    setTimeout(()=>{
                        this.btnLoading = false;
                    },3000)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 删除
        clickDelete(row){
            this.$confirm('此操作将永久删除该风格, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/customerservicev1/scene/deleteStyle',{id: row.styleTypeId},(res)=>{
                    this.getStyleIndex();
                    this.$message.success('删除成功！')
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 获取风格列表数据
        getStyleIndex(){
            this.loading = true;
            common.connect('/customerservicev1/scene/styleIndex',{},(res)=>{
                this.tableData = res.data;
                this.loading = false;
            })
        },
        handleClose(){
            this.isShowScene = false;
        },
        // handleCurrentChange(){}
    },
}
</script>

<style lang='scss'>
.styleType{
    .add-wrap{
        display: flex;
        justify-content: flex-end;
    }
    .table-wrap{
        margin-top: 20px;
        .image{
            width: 40px;
            cursor: pointer;
        }
        .operation-wrap{
            display: flex;
            justify-content: center;
            .iconfont{
                font-size: 16px;
                padding: 0 10px;
                cursor: pointer;
            }
        }
        .pagination-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;
        }
    }
    .dialog-wrap{
        width: 540px;
        .title{
            font-size: 16px;
            color: #000;
            margin-left: 20px;
        }
        .content{
            margin: 40px 20px 20px 20px;
            .item{
                padding: 20px 0;
                .tit{
                    padding: 10px 0;
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            padding: 15px 0;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
