<!--
 * @Descripttion: 全景类型
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-11 10:27:05
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-12 09:44:31
-->
<template>
    <div class="sceneType">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">全景类型</div>
        </div>
        <div class="case-wrap">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="功能类型" name="first">
                    <typeFunction ref="typeFunction"></typeFunction>
                </el-tab-pane>
                <el-tab-pane label="风格类型" name="second">
                    <styleType ref="styleType"></styleType>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import typeFunction from './typeComponents/typeFunction'
import styleType from './typeComponents/styleType'
export default {
    components:{
        styleType,
        typeFunction,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'sceneType',
            activeName: 'first',
        }
    },
    mounted () {

    },
    methods: {

    },
}
</script>

<style lang='scss'>
.sceneType{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
    }
}
</style>
